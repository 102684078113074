<template>
  <q-step
    :name="5"
    title="3rd-party tools"
    icon="add_comment"
    caption="Optional"
    :done="this.update_step > 5"
  >
    <div class="row" style="margin-top: 10px">

      <q-toggle
        :model-value="install_knative"
        @update:model-value="$emit('install_knative', $event)"
        label="Install knative"
        class="col-2"
      />

      <q-toggle
        :model-value="install_nfs"
        @update:model-value="$emit('install_nfs', $event)"
        label="Install NFS"
        class="col-2"
        />
        <q-toggle
          :model-value="install_harbor"
          label="Install Harbor"
          @update:model-value="$emit('install_harbor', $event)"
          class="col-2"
        />
        <q-toggle
          :model-value="install_fluent_bit"
          label="Install fluent-bit"
          @update:model-value="$emit('install_fluent_bit', $event)"
          class="col-2"
        />
      </div>
    
    <div class="row" style="margin-top: 10px">

      <!-- <q-toggle
        :model-value="installMPIOperator"
        label="Install mpi-operator"
        @update:model-value="$emit('install_mpi_operator', $event)"
        class="col-2"
      /> -->

      <q-toggle
        :disable="!kubernetes_version || +kubernetes_version?.slice(0,4) > 1.25"
        :model-value="install_kubeflow"
        label="Install kubeflow"
        @update:model-value="$emit('install_kubeflow', $event)"
        class="col-2"
      />      

      <!-- <q-toggle
        :disable="!(kubernetes_flavour === 'bcm' && installation_type.toLowerCase() === 'saas')"
        :model-value="cluster_installer_enabled"
        label="Cluster installer enabled"
        @update:model-value="$emit('cluster_installer_enabled', $event)"
        class="col-2"
      />       -->

      <q-toggle
        class="col-2"
        :disable="install_kubeflow"
        :model-value="install_training_operator"
        @update:model-value="$emit('install_training_operator', $event)"
        label="Install training-operator"
      />

      <q-toggle
        class="col-2"
        :model-value="enable_kwok"
        @update:model-value="$emit('enable_kwok', $event)"
        label="Enable Kwok"
      />

      <q-toggle
       class="col-2"
       :model-value="ingressController.enabled"
       @update:model-value="updateIngressController('enabled', $event)"
       label="Enable Ingress Controller"
      />
    </div>
    <transition name="slide-fade">
        <div v-if="enable_kwok" class="x">
          <q-input
            stack-label
            label="kwok ratio"
            :model-value="kwok_ratio"
            @update:model-value="$emit('kwok_ratio', $event)"
            style="margin-bottom: 10px; width: 100px"
          />
        </div>
      </transition>
    <transition name="slide-fade">
      <div v-if="ingressController.enabled" class="x">
        <q-select
          stack-label
          label="ingress controller type (Optional)"
          :model-value="ingressController.type"
          @update:modelValue="updateIngressController('type', $event)"
          :options="ingressControllerTypeOptions()"
          style="margin-bottom: 10px; width: 215px"
        />
      </div>
    </transition>
    
    <q-stepper-navigation>
      <q-btn
        flat
        @click="$emit('update_step', 4)"
        color="primary"
        label="Back"
        class="q-ml-sm"
      />
      <q-btn
      color="primary"
      label="Finish"
      @click="$emit('yaml_dialog_state', true)"
    />
    </q-stepper-navigation>
  </q-step>
</template>

<script>
export default {
  components: {},
  methods: {
    async onClick() {
      this.$emit("update_step", 6);
    },
    updateIngressController(key, value){
      console.log(key, value)
      this.$emit('updateIngressController', {...this.ingressController, [key]: value})
    },
    ingressControllerTypeOptions(){
      if (this.kubernetes_flavour === "rke2") {
        return ["nginx", "rke2"];
      }
      return ["nginx"];
    },
  },
  props: {
    install_fluent_bit: {
      type: Boolean,
      required: true,
    },
    kubernetes_flavour: {
      type: String,
      required: true,
    },
    installation_type: {
      type: String,
      required: true,
    },
    cluster_installer_enabled: {
      type: Boolean,
      required: true,
    },
    kubernetes_version: {
      type: String,
      required:true
    },
    update_step: {
      type: Number,
      required: true,
    },
    install_training_operator: {
      type: Boolean,
      required: true,
    },
    ingressController: {
      type: Object,
      required: true,
    },
    enable_kwok: {
      type: Boolean,
      required: true,
    },
    install_kubeflow: {
      type: Boolean,
      required: true,
    },
    install_mpi_operator: {
      type: Boolean,
      required: true,
    },
    install_nfs: {
      type: Boolean,
      required: true,
    },
    install_knative: {
      type: Boolean,
      required: true,
    },
    install_harbor: {
      type: Boolean,
      required: true,
    },
    kwok_ratio: {
      type: Number,
      required: true,
    },
    enable_multi_tenancy: {
      type: Boolean,
      required: true
    },
  }
};
</script>
