<template>
  <q-step
    :name="3"
    title="Run:AI settings"
    icon="settings"
    :done="this.update_step > 3"
  >
    <q-form ref="runai-settings-form">
      <div>
        <q-select
          label="Run:AI Installation type"
          v-model="installationType"
          :options="installationTypeOptions()"
          style="margin-bottom: 20px; width: 250px"
          @update:model-value="$emit('installation_type', $event)"
          class="col-8"
        />
        <q-tooltip :delay="1000">
          This is the type of Run:AI installation.<br />
          Self-Hosted - Run:AI control-plane & cluster will be installed on your
          Kubernetes cluster.<br />
          SaaS - Run:AI cluster will be installed and will operate with a SaaS
          tenant (envinaclick).<br />
          Air-Gap - Run:AI control-plane & cluster will installed as Air-Gapped
          env.<br />
          Multi-Tenancy - Run:AI control-plane with multi tenancy enabled will be installed on your
          Kubernetes cluster.<br />
          None - Run:AI will not be installed.
        </q-tooltip>
      </div>

      <div class="row" style="margin-top: 10px">
        <div>
          <q-select
            label="Control plane environment"
            v-if="installationType === 'Self-Hosted' || installation_type === 'Multi-Tenancy'"
            v-model="controlPlaneEnvironment"
            :options="controlPlaneEnvironmentOptions"
            @update:model-value="
              getControlPlaneChartVersions(
                'control-plane',
                controlPlaneEnvironment,
                'backend-charts',
                $event
              )
            "
            style="margin-bottom: 20px; width: 200px; margin-left: 20px"
            class="col-8"
          />
          <q-tooltip :delay="1000">
            This is the environment that the Run:AI control-plane will be
            installed from.<br />
            prod - Production environment.<br />
            staging - Staging environment.<br />
            test - Test environment.<br />
            lab - Lab environment.
          </q-tooltip>
        </div>

        <div>
          <q-select
            label="Control plane version"
            v-if="installationType === 'Self-Hosted' || installation_type === 'Multi-Tenancy'"
            v-model="controlPlaneVersion"
            :loading="cpVersionsLoading"
            :disable="cpVersionsLoading"
            :options="controlPlanFilteredOptions"
            @filter="controlPlaneFilterFn"
            use-input
            input-debounce="0"
            style="margin-bottom: 20px; width: 350px; margin-left: 40px"
            class="col-8"
            @update:model-value="$emit('control_plane_version', $event)"
            :rules="[controlPlaneVersionRequiredRule]"
          />
          <q-tooltip :delay="1000">
            This is the version of the Run:AI control-plane that will be
            installed.
          </q-tooltip>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div>
          <q-select
            label="Air-Gap environment"
            v-if="installationType === 'Air-Gap'"
            v-model="airGapEnvironment"
            :options="airGapEnvironmentOptions"
            :disable="airGapVersionsLoading"
            @update:model-value="
              getAirGapTarVersions(airGapEnvironment, $event)
            "
            style="margin-bottom: 20px; width: 200px; margin-left: 20px"
            class="col-8"
          />
          <q-tooltip :delay="1000">
            This is the environment that the Run:AI Air-Gap tar will be
            installed.<br />
            prod - Production environment.<br />
            staging - Staging environment.<br />
          </q-tooltip>
        </div>

        <div>
          <q-select
            label="Air-Gap tar version"
            v-if="installationType === 'Air-Gap'"
            v-model="airGapVersion"
            :loading="airGapVersionsLoading"
            :disable="airGapVersionsLoading"
            :options="airGapFilteredOptions"
            @filter="airGapFilterFn"
            use-input
            input-debounce="0"
            style="margin-bottom: 20px; width: 350px; margin-left: 40px"
            class="col-8"
            @update:model-value="updateAirGapVersion($event)"
            :rules="[airGapVersionRequiredRule]"
          />
          <q-tooltip :delay="1000">
            This is the version of the Run:AI Air-Gap tar that will be
            installed.
          </q-tooltip>
        </div>
      </div>
      <div class="row" style="margin-top: 10px">
        <div>
          <q-select
            v-if="
              installationType === 'SaaS' || installationType === 'Self-Hosted'
            "
            label="Cluster environment"
            v-model="clusterEnvironment"
            :options="clusterEnvironmentOptions"
            @update:model-value="
              this.getClusterChartVersions(
                'runai-cluster',
                clusterEnvironment,
                'run-ai-charts',
                $event
              )
            "
            style="margin-bottom: 20px; width: 200px; margin-left: 20px"
            class="col-8"
          />
          <q-tooltip :delay="1000">
            This is the environment that the Run:AI cluster will be installed
            from.<br />
            prod - Production environment.<br />
            staging - Staging environment.<br />
            test - Test environment.<br />
            lab - Lab environment.
          </q-tooltip>
        </div>

        <div>
          <q-select
            v-if="
              installationType === 'SaaS' || installationType === 'Self-Hosted'
            "
            label="Cluster version"
            v-model="clusterVersion"
            :options="clusterFilteredOptions"
            :loading="clusterVersionsLoading"
            :disable="clusterVersionsLoading"
            @filter="clusterFilterFn"
            use-input
            input-debounce="0"
            style="margin-bottom: 20px; width: 350px; margin-left: 40px"
            class="col-8"
            @update:model-value="$emit('cluster_version', $event)"
            :rules="[clusterVersionRequiredRule]"
          />
          <q-tooltip :delay="1000">
            This is the version of the Run:AI cluster that will be installed.
          </q-tooltip>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <div>
          <q-select
            v-if="installationType === 'Engine'"
            label="Engine Environment"
            v-model="engineEnvironment"
            :options="engineEnvironmentOptions"
            @update:model-value="
              this.getEngineChartVersions(
                'runai-engine',
                engineEnvironment,
                'run-ai-charts',
                $event
              )
            "
            style="margin-bottom: 20px; width: 200px; margin-left: 20px"
            class="col-8"
          />
          <q-tooltip :delay="1000">
            This is the environment that the Run:AI Engine will be installed
            from.<br />
            prod - Production environment.<br />
            staging - Staging environment.<br />
            test - Test environment.<br />
            lab - Lab environment.
          </q-tooltip>
        </div>

        <div>
          <q-select
            v-if="installationType === 'Engine'"
            label="Engine version"
            v-model="engineVersion"
            :options="engineFilteredOptions"
            :loading="engineVersionsLoading"
            :disable="engineVersionsLoading"
            @filter="engineFilterFn"
            use-input
            input-debounce="0"
            style="margin-bottom: 20px; width: 350px; margin-left: 40px"
            class="col-8"
            @update:model-value="$emit('engine_version', $event)"
            :rules="[engineVersionRequiredRule]"
          />
          <q-tooltip :delay="1000">
            This is the version of the Run:AI Engine that will be installed.
          </q-tooltip>
        </div>
      </div>

      <div class="row" style="margin-top: 10px">
        <q-toggle
            class="col-2"
            :model-value="enable_alpha_flags"
            @update:model-value="$emit('enable_alpha_flags', $event)"
            label="Enable Alpha-flags"
        />
        <q-toggle
            :model-value="enable_sso"
            @update:model-value="$emit('enable_sso', $event)"
            label="Enable SSO"
            class="col-2"
        />
        <q-toggle
            :disable="installation_type !== 'Multi-Tenancy'"
            :model-value="is_csp_env"
            label="is csp env"
            @update:model-value="$emit('is_csp_env', $event)"
            class="col-2"
        />
        <q-toggle
          class="col-2"
          :model-value="subdomain_support_enabled"
          @update:model-value="$emit('subdomain_support_enabled', $event)"
          label="Subdomain support"
        />
        <q-toggle
            class="col-2"
            :model-value="customTenant.enabled"
            @update:model-value="updateCustomTenant('enabled', $event)"
            label="Enable custom-tenant"
        />
      </div>
      <transition name="slide-fade">
        <div v-if="customTenant.enabled" class="x">
          <div class="q-mt-lg">Custom tenant specs</div>
          <div class="row q-gutter-xl">
            <q-input
                stack-label
                label="control-plane url"
                :model-value="customTenant.url"
                @update:model-value="updateCustomTenant('url', $event)"
                style="margin-bottom: 10px; width: 250px"
            />
            <q-input
                stack-label
                label="name"
                :model-value="customTenant.name"
                @update:model-value="updateCustomTenant('name', $event)"
                style="margin-bottom: 10px; width: 250px"
            />
            <q-input
                stack-label
                label="clientID"
                :model-value="customTenant.clientID"
                @update:model-value="updateCustomTenant('clientID', $event)"
                style="margin-bottom: 10px; width: 250px"
            />
          </div>

          <div class="row q-gutter-xl">
            <q-input
                stack-label
                label="clientSecret"
                :model-value="customTenant.clientSecret"
                @update:model-value="updateCustomTenant('clientSecret', $event)"
                style="margin-bottom: 10px; width: 250px"
            />
            <q-input
                stack-label
                label="username"
                :model-value="customTenant.username"
                @update:model-value="updateCustomTenant('username', $event)"
                style="margin-bottom: 10px; width: 250px"
            />
            <q-input
                stack-label
                label="password"
                :model-value="customTenant.clientPassword"
                @update:model-value="updateCustomTenant('clientPassword', $event)"
                style="margin-bottom: 10px; width: 250px"
            />
          </div>
        </div>
      </transition>
      <q-stepper-navigation>
        <q-btn
          flat
          @click="$emit('update_step', 2)"
          color="primary"
          label="Back"
          class="q-ml-sm"
        />
        <q-btn @click="onClick" color="primary" label="Continue" />
      </q-stepper-navigation>
    </q-form>
  </q-step>
</template>

<script>
import axios from "axios";
export default {
  components: {},
  methods: {
    async onClick() {
      if (this.installationType === "None") {
        this.$emit("update_step", 4);
      } else if (await this.$refs["runai-settings-form"].validate()) {
        this.$emit("update_step", 4);
      }
    },
    airGapFilterFn(val, update) {
      if (val === "") {
        update(() => {
          this.airGapFilteredOptions = this.airGapVersionOptions;
        });
        return;
      }
      update(() => {
        const needle = val.toLowerCase();
        this.airGapFilteredOptions = this.airGapVersionOptions.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    controlPlaneFilterFn(val, update) {
      if (val === "") {
        update(() => {
          this.controlPlanFilteredOptions = this.controlPlaneVersionOptions;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.controlPlanFilteredOptions =
          this.controlPlaneVersionOptions.filter(
            (v) => v.toLowerCase().indexOf(needle) > -1
          );
      });
    },
    clusterFilterFn(val, update) {
      if (val === "") {
        update(() => {
          this.clusterFilteredOptions = this.clusterVersionOptions;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.clusterFilteredOptions = this.clusterVersionOptions.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    engineFilterFn(val, update) {
      if (val === "") {
        update(() => {
          this.engineFilteredOptions = this.engineVersionOptions;
        });
        return;
      }

      update(() => {
        const needle = val.toLowerCase();
        this.engineFilteredOptions = this.engineVersionOptions.filter(
          (v) => v.toLowerCase().indexOf(needle) > -1
        );
      });
    },
    clusterVersionRequiredRule(val) {
      if (!val) {
        return "Cluster version is required";
      }
      return true;
    },
    airGapVersionRequiredRule(val) {
      if (!val) {
        return "Air-Gap tar version is required";
      }
      return true;
    },
    engineVersionRequiredRule(val) {
      if (!val) {
        return "Engine version is required";
      }
      return true;
    },
    controlPlaneVersionRequiredRule(val) {
      if (!val) {
        return "Control plane version is required";
      }
      return true;
    },
    updateCustomTenant(key, value){
      this.$emit('updateCustomTenant', {...this.customTenant, [key]: value})
    },
    async getControlPlaneChartVersions(
      chartName,
      environment,
      repositoryName,
      event
    ) {
      this.cpVersionsLoading = true;
      this.controlPlaneVersion = "latest";
      this.controlPlaneVersionOptions = await axios
        .get(
          `https://env-in-a-click-generator-ui.runailabs.com/api/chart-versions?chartName=${chartName}&environment=${environment}&repositoryName=${repositoryName}`
        )
        .then((response) => {
          this.cpVersionsLoading = false;
          return response.data;
        });
      this.$emit("control_plane_environment", event);
    },
    async getClusterChartVersions(
      chartName,
      environment,
      repositoryName,
      event
    ) {
      this.clusterVersionsLoading = true;
      this.clusterVersion = "latest";
      this.clusterVersionOptions = await axios
        .get(
          `https://env-in-a-click-generator-ui.runailabs.com/api/chart-versions?chartName=${chartName}&environment=${environment}&repositoryName=${repositoryName}`
        )
        .then((response) => {
          this.clusterVersionsLoading = false;
          return response.data;
        });
      this.$emit("cluster_environment", event);
    },
    async getAirGapTarVersions(environment, event) {
      this.airGapVersionsLoading = true;
      this.airGapVersionOptions = await axios
        .get(
          `https://env-in-a-click-generator-ui.runailabs.com/api/air-gap-tar-versions?environment=${environment}`
        )
        .then((response) => {
          this.airGapVersionsLoading = false;
          return response.data;
        });
      this.$emit("air_gap_environment", event);
    },
    installationTypeOptions() {
      if (
        this.kubernetes_flavour !== "op" &&
        this.kubernetes_flavour !== "openshift"
      ) {
        if (this.installationType === "Air-Gap") {
          this.installationType = "Self-Hosted";
          this.$emit("installation_type", "Self-Hosted");
        }
        return ["Self-Hosted", "SaaS", "Engine", "Multi-Tenancy", "None"];
      }
      return ["Self-Hosted", "SaaS", "Engine", "Air-Gap", "None"];
    },
    updateAirGapVersion(event) {
      this.$emit("air_gap_version", event);
      this.$emit("cluster_version", event);
      this.$emit("control_plane_version", event);
    },
    async getEngineChartVersions(
      chartName,
      environment,
      repositoryName,
      event
    ) {
      this.engineVersionsLoading = true;
      this.engineVersion = "latest";
      this.engineVersionOptions = await axios
        .get(
          `https://env-in-a-click-generator-ui.runailabs.com/api/chart-versions?chartName=${chartName}&environment=${environment}&repositoryName=${repositoryName}`
        )
        .then((response) => {
          this.engineVersionsLoading = false;
          return response.data;
        });
      this.$emit("engine_environment", event);
    },
  },
  props: {
    kubernetes_flavour: {
      type: String,
      required: true,
    },
    update_step: {
      type: Number,
      required: true,
    },
    installation_type: {
      type: String,
      required: true,
    },
    air_gap_environment: {
      type: String,
      required: true,
    },
    air_gap_version: {
      type: String,
      required: true,
    },
    control_plane_environment: {
      type: String,
      required: true,
    },
    control_plane_version: {
      type: String,
      required: true,
    },
    cluster_environment: {
      type: String,
      required: true,
    },
    engine_environment: {
      type: String,
      required: true,
    },
    engine_version: {
      type: String,
      required: true,
    },
    cluster_version: {
      type: String,
      required: true,
    },
    enable_alpha_flags: {
      type: Boolean,
      required: true,
    },
    is_csp_env: {
      type: Boolean,
      required: true
    },
    customTenant: {
      type: Object,
      required: true,
    },
    enable_sso: {
      type: Boolean,
      required: true,
    },
    subdomain_support_enabled: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      cpVersionsLoading: false,
      clusterVersionsLoading: false,
      airGapVersionsLoading: false,
      engineVersionsLoading: false,
      step: this.update_step,
      installationType: this.installation_type,
      controlPlaneEnvironment: this.control_plane_environment,
      controlPlaneEnvironmentOptions: ["prod", "staging", "test", "lab"],
      controlPlaneVersion: this.control_plane_version,
      airGapEnvironment: this.air_gap_environment,
      airGapEnvironmentOptions: ["prod", "staging", "test"],
      airGapVersion: this.air_gap_version,
      clusterEnvironment: this.cluster_environment,
      clusterEnvironmentOptions: ["prod", "cr", "staging", "test", "lab"],
      engineEnvironment: this.engine_environment,
      engineEnvironmentOptions: ["prod", "staging", "test", "lab"],
      engineVersion: this.engine_version,
      clusterVersion: this.cluster_version,
      controlPlaneVersionOptions: this.getControlPlaneChartVersions(
        "control-plane",
        "prod",
        "backend-charts"
      ),
      clusterVersionOptions: this.getClusterChartVersions(
        "runai-cluster",
        "prod",
        "run-ai-charts"
      ),
      engineVersionOptions: this.getEngineChartVersions(
        "runai-engine",
        "prod",
        "run-ai-charts"
      ),
      airGapVersionOptions: this.getAirGapTarVersions("prod"),
      clusterFilteredOptions: this.clusterVersionOptions,
      engineFilteredOptions: this.engineVersionOptions,
      controlPlanFilteredOptions: this.controlPlaneVersionOptions,
      airGapFilteredOptions: this.airGapVersionOptions,
    };
  },
};
</script>
